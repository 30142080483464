.file-card {
  border-radius: 10px;
  background-color: #F6F6F7;
  padding: 20px;
  position: sticky;
  top: 0;
  flex-wrap: nowrap;
  justify-content: normal;
  align-items: normal;
  align-content: normal;

  .file-card-content {
    display: flex;
    flex-direction: row;

    .icon-file:nth-child(1) {
      display: block;
      flex-grow: 0;
      flex-shrink: 1;
      align-self: auto;
      order: 0;
      margin-right: 15px;
    }

    .content:nth-child(2) {
      display: block;
      flex-grow: 0;
      flex-shrink: 1;
      align-self: auto;
      order: 0;
      margin-right: 15px;

      .file-name {
        color: #4F5B68;
        font-size: 14px;
        font-weight: 400;
        white-space: nowrap;
      }
    }

    .icon-delete:nth-child(3) {
      display: block;
      flex-grow: 0;
      flex-shrink: 1;
      align-self: center;
      order: 0;
      width: 100%;
      text-align: right;
      color: #FF7F8F;
      font-size: 20px;
    }
  }
}