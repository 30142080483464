.dashboard {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.dashboard-column {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
  width: 31%;
  height: 100%;
}

.dashboard-item {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.dashboard-title {
  font-size: 16pt;
}

.dashboard-update {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.close {
  border: none;
  background: transparent;
}

.intercom-state {
  &::before {
    border-radius: 100%;
    content: "";
    display: inline-block;
    height: 10px;
    margin-right: 8px;
    position: relative;
    width: 10px;
  }

  &.pending-promote::before,
  &.pending-update::before {
    background-color: $pending-color;
  }

  &.pending-remove::before {
    background-color: #b4bbc3;

  }

  &.not-promoted::before {
    background-color: $not-promoted-color;
  }

  &.promoted::before {
    background-color: $promoted-color;
  }
}

.content-state-action-intercom {
  display: inline-flex;
  gap: 2em;

  .state-description {
    margin: auto;

    span {
      white-space: nowrap;
    }
  }

  button {
    margin-left: 10px;
  }

  .loadingView {
    background-color: #fff;
  }
}

.bulk-promote-content {
  .not-promoted {
    color: $not-promoted-color;
  }

  .promoted {
    color: $promoted-color;
  }
}

@media screen and (max-width: 975px) {
  .content-state-action-intercom {
    grid-gap: 1em;
    gap: 1em;
  }
}

@media screen and (max-width: 754px) {
  .content-state-action-intercom {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin-left: 0;
    margin-right: auto;

    .state-description {
      margin-left: 0;
    }

    button {
      margin-left: 0 !important;
    }
  }
}