.import-intercoms-modal {

  .text-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }
}

.progress-bar-container {
  margin-top: 10px;
  margin-left: 30px;

  .progress-bar {
    display: flex;
    flex-direction: row;

    .bar-loaded {
      background-color: #0088CE;
      height: 5px;
    }

    .bar-loading {
      background-color: #B3BAC2;
      height: 5px;
    }
  }
}