.header {
  display: flex;
  justify-content: space-between;

  .title {
    font-weight: 700;
  }
}

.i {
  font-size: 22px;
}