.validate-card {
  margin: 20px 0;
  padding: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: normal;
  align-items: normal;
  align-content: normal;

  &.first-card {
    margin-top: 30px;
  }

  .icon:nth-child(1) {
    display: block;
    flex-grow: 0;
    flex-shrink: 1;
    align-self: auto;
    order: 0;
    margin-right: 15px;
    font-size: 20px;
    margin-top: -4px;
  }

  .content:nth-child(2) {
    display: block;
    flex-grow: 0;
    flex-shrink: 1;
    align-self: auto;
    order: 0;

    .title {
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 500;
    }
  }

  &.warning {
    background-color: #FFF6E0;

    .icon:nth-child(1) {
      color: #8C6B0D;
    }

    .title {
      color: #8C6B0D;
    }
  }

  &.error {
    background-color: #ffe5e8;

    .icon:nth-child(1) {
      color: #990013;
    }

    .title {
      color: #990013;
    }
  }

  &.success {
    background-color: #D2F9E6;

    .icon:nth-child(1) {
      color: #11884D;
    }

    .title {
      color: #11884D;
    }
  }
}