.modal-backdrop  {
  z-index: 99999;
}

.co-modal {
  z-index: 99999;
}

.register-container {
  background-color: rgba(79, 91, 104, 0.05);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  align-items: center;

  .icon-file {
    display: inline-flex;
    gap: 15px;
    align-items: center;

    .file {
      display: flex;
      flex-direction: column;
      line-height: 1.2;

      .date {
        font-weight: 400;
        font-size: 10px;
        color: rgb(179, 186, 194);
      }
    }
  }

  .actions-icons {
    display: flex;
    gap: 30px;

    .visibility-hidden {
      visibility: hidden;
    }
  }
}