.import-intercoms-modal {

  .modal-content {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .modal-header {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    gap: 30px
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    padding-top: 0;
    padding-left: 78px;
    padding-right: 78px;

    .files-container {
      .loadingView {
        background-color: transparent;
      }
    }
  }

  .modal-footer {
    padding-left: 78px;
    padding-right: 78px;
    border-top: none;
  }

  .text-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .dropzone-container {
    width: 100%;
    display: flex;
    flex-grow: 1;

    .dropzone {
      width: 100%;
    }
  }

  .bottom-container {
    display: flex;
    align-self: last baseline;
    margin-top: 25px;
    gap: 40px;

    .left-content {
      width: 50%;
    }

    .right-content {
      width: 50%;

      .message-container {
        min-height: 203px;
      }
    }
  }
}

.progress-bar-container {
  margin-top: 10px;
  margin-left: 30px;

  .progress-bar {
    display: flex;
    flex-direction: row;

    .bar-loaded {
      background-color: #0088CE;
      height: 5px;
    }

    .bar-loading {
      background-color: #B3BAC2;
      height: 5px;
    }
  }
}