.file-card {

  .file-time {
    color: rgb(179, 186, 194);
    font-size: 9px;
    font-weight: 400;
  }
}

.validate-scroll-container {
  max-height: calc(100vh - 190px);

  .validate-list {
    overflow: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 0 !important;
    }

    .buttons-container {
      text-align: right;
      position: sticky;
      bottom: 0;
      width: 100%;
      background-color: white;
      height: 60px;

      .button-left {
        margin-right: 20px;
      }
    }
  }
}